<template>
  <b-form @submit.stop.prevent>
    <h3>{{ $t('booking.internal-info-title') }}</h3>
    <b-form-group id="input-group-1" :label="`${$t('booking.clerk-name')}:`" label-for="input-1">
      <b-form-input
        id="input-1"
        v-model="clerkName"
        type="text"
        disabled
        v-if="isMEMSHAKAgent"
      ></b-form-input>

      <v-select
        v-model="clerkName"
        :options="clerkNameList"
        :clearable="false"
        :searchable="true"
        v-else
      >
      </v-select>
    </b-form-group>

    <b-form-group id="input-group-2" :label="`${$t('booking.agent-list')}:`" label-for="input-2">
      <v-select
        v-model="agent"
        :reduce="agent => agent.value"
        :options="agentList"
        :clearable="false"
        :searchable="true"
        :disabled="!isMEMSHAKAgent || isExternal"
      >
      </v-select>
    </b-form-group>
    <b-form-group id="input-group-3" :label="isMEMSHAKAgent ? `${$t('booking.discount-amount')}:` : `${$t('search-result.net-price')}:`" label-for="input-3">
      <b-form-input
        id="input-1"
        v-model="salesAmount"
        type="text"
        class="agent-amount"
        :state="!!Number(salesAmount.substr(1))"
        @change="updateAmount"
      ></b-form-input>
    </b-form-group>

    <b-form-group id="input-group-5" :label="`${$t('booking.remark-by-agent')}:`" label-for="input-5">
      <b-form-textarea
        id="input-5"
        v-model="agentRemark"
        rows="3"
        max-rows="6"
        :placeholder="$t('booking.remark-by-agent')"
        @change="changeRemark"
        :disabled="isExternal"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group id="input-group-6" :label="`${$t('booking.remark-to-hotel')}:`" label-for="input-6" v-if="!bypassPayment || isMEMSHAKAgent">
      <b-form-textarea
        id="input-5"
        v-model="hotelRemark"
        rows="3"
        max-rows="6"
        :placeholder="$t('booking.remark-to-hotel')"
        @change="changeHotelRemark"
        :disabled="isExternal"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group id="input-group-4">
      <b-form-checkbox v-model="bypassPayment" :value="true" :disabled="isExternal">{{ $t('booking.bypass-payment') }}</b-form-checkbox>
    </b-form-group>
    <b-form-group id="input-group-9" :label="`${$t('booking.pnr-update')}:`" label-for="input-8" v-if="!retryState">
      <b-form-input
        id="input-8"
        v-model="pnrToUpdate"
        type="text"
        class="agent-amount"
      ></b-form-input>
    </b-form-group>
  </b-form>
</template>

<script>
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { BForm, BFormGroup, BFormInput, BFormCheckbox, BFormTextarea } from 'bootstrap-vue';

export default {
  name: 'InternalAgentInformation',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
  },
  props: {
    currency: {
      type: String,
      default: 'US',
    },
    fullPrice: {
      type: Number,
      default: 0,
    },
    netPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      agent: '',
      // salesAmount: '',
      clerkName: '',
      agentRemark: '',
      hotelRemark: '',
      initialBalance: 0,
      bypassPayment: false,
      pnrToUpdate: '',
    };
  },
  computed: {
    ...mapGetters({
      allAgentList: 'GET_ALL_AGENT_LIST',
      agentContent: 'GET_ODYSSEY_AGENT_CONTENT',
      loginClerkName: 'GET_ODYSSEY_AGENT_CLERK_NAME',
      odyAgentCode: 'GET_LOGIN_ODYSSEY_AGENT_CODE',
      isMEMSHAKAgent: 'IS_MEMSHAK_ODYSSEY_AGENT_CODE',
      externalBookingInfo: 'GET_EXTERNAL_BOOKING_INFO',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
    }),
    isExternal() {
      return this.externalBookingInfo !== null;
    },
    agentList() {
      const list = this.allAgentList;
      if (list.length === 0) return [];
      return list.map((elm, inx) => ({ id: inx, value: elm.agt_Code, label: `${elm.agt_Code} - ${elm.agt_Name}` }));
    },
    clerkNameList() {
      return ['Annap', 'Lena', 'Vikas'];
    },
    salesAmount: {
      get() {
        return `$${this.initialBalance}`;
      },
      set(newValue) {
        this.initialBalance = Number(newValue.split(/ /)[0].replace(/[^\d]/g, ''));
      },
    },
    retryState() {
      const retry = JSON.parse(window.localStorage.getItem('retryState'));
      return retry != null;
    },
  },
  watch: {
    bypassPayment() {
      this.update();
      this.$store.commit('SET_ODYSSEY_BYPASS_PAYMENT', this.bypassPayment);
    },
    clerkName() {
      this.update();
    },
    salesAmount() {
      this.update();
    },
    isExternal() {
      if (this.isExternal) {
        this.agent = this.externalBookingInfo.bookingData.odyAgentCode;
        this.bypassPayment = this.externalBookingInfo.bookingData.bookTransaction.bypassPaymentByAgent;
      }
    },
    pnrToUpdate() {
      this.update();
    },
  },
  async created() {
    await this.$store.dispatch('FETCH_ALL_AGENT_LIST');
  },
  mounted() {
    this.agent = this.odyAgentCode;
    // this.salesAmount = `$${this.netPrice}`; // this.isMEMSHAKAgent ? `$${this.fullPrice}` : `$${this.netPrice}`;
    // eslint-disable-next-line no-nested-ternary
    this.salesAmount = this.isExternal ? `$${this.netPrice}` : this.isMEMSHAKAgent ? `$${this.fullPrice}` : `$${this.netPrice}`;
    this.clerkName = this.isMEMSHAKAgent ? this.loginClerkName : this.clerkNameList[0];
    this.bypassPayment = this.bypassPaymentState;
    this.update();
    if (this.isExternal) {
      this.agent = this.externalBookingInfo.bookingData.odyAgentCode;
      this.bypassPayment = this.externalBookingInfo.bookingData.bookTransaction.bypassPaymentByAgent;
    }
  },
  methods: {
    update() {
      if (this.isExternal) return;

      this.$emit('updateInternalAgentInfo', {
        clerkName: this.clerkName,
        OdyAgentCodeByAgent: this.agent,
        SalesAmount: +(this.salesAmount.substr(1)),
        BypassPaymentByAgent: this.bypassPayment,
        remarkByAgent: this.agentRemark,
        pnrToUpdate: this.pnrToUpdate,
        remarkToHotel: (this.bypassPayment && !this.isMEMSHAKAgent) ? '' : this.hotelRemark,
      });
    },
    changeRemark() {
      this.update();
    },
    changeHotelRemark() {
      this.update();
    },
    updateAmount() {
      const salesAmount = +(this.salesAmount.substr(1));
      if (salesAmount < this.netPrice) {
        this.salesAmount = `$${this.netPrice}`;
        const message = this.$t('booking.toast-incorrect-amount');
        // const h = this.$createElement;
        const id = 'error-toast';
        // const $closeButton = h(
        //   'b-button',
        //   {
        //     on: { click: () => this.$bvToast.hide(id) },
        //   },
        //   'Close',
        // );
        this.$bvToast.toast([message], {
          id,
          title: 'ERROR',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          noCloseButton: true,
          bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
        });
      }
    },
  },
};
</script>
